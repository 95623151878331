<template>
  <!--/Header-->
  <header id="site-header" class="fixed-top">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light stroke py-lg-0">
        <h1>
          <a class="navbar-brand pe-xl-5 pe-lg-4" href="/">
            <!-- <span class="w3yellow">Eco</span>Latas -->
            <img src="@/assets/logo.png" alt="Logo" >
          </a>
        </h1>
        <div></div>
        <div></div>
        <div></div>
        <button
          class="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <a href="#search" class="btn search-btn" title="search">
            <span class="fas fa-search me-2" aria-hidden="true"></span>
          </a>
        </button>
        <button
          class="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon fa icon-expand fa-bars"></span>
          <span class="navbar-toggler-icon fa icon-close fa-times"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarScroll">
          <ul class="navbar-nav me-lg-auto my-2 my-lg-0 navbar-nav-scroll">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="/"
                >Home</a
              >
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="about.html">About</a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="/#Pages"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Products <span class="fa fa-angle-down ms-1"></span>
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a class="dropdown-item pt-2" href="products.html"
                    >Products</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="product-single.html"
                    >Product Single</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="checkout.html">Cart Page</a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="/#Pages"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Pages <span class="fa fa-angle-down ms-1"></span>
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a class="dropdown-item pt-2" href="blog.html">Blog Page</a>
                </li>
                <li>
                  <a class="dropdown-item" href="blog-single.html"
                    >Blog Single</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="elements.html">Elements</a>
                </li>
                <li>
                  <a class="dropdown-item" href="error.html">Error Page</a>
                </li>
                <li><a class="dropdown-item" href="faq.html">FaQ</a></li>
              </ul>
            </li> -->
            <!-- <li class="nav-item">
              <a class="nav-link" href="contact.html">Contact</a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link" href="/produtos">Produtos</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/marcas">Marcas</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/sobre">Sobre</a>
            </li>
            <li class="nav-item search-right">
              <a href="#search" style="padding: 18px 0;" class="btn search-btn" title="search"
                >
                <span style="color: var(--nav-color); font-size: 13px; text-transform: uppercase; font-weight: 600; padding: 0px 5px;">Pesquisa</span>
                <span class="fas fa-search me-2" aria-hidden="true"></span
              ></a>
              <!-- search popup -->
              <div id="search" class="pop-overlay">
                <div class="popup">
                  <h3 class="title-w3l two mb-4 text-left">Pesquisa {{ search_.qs }}</h3>
                  <form
                    action="/produtos"
                    method="GET"
                    class="search-box d-flex position-relative"
                  >
                    <input
                      type="search"
                      placeholder="Informe uma pesquisa"
                      name="search"
                      required="required"
                      autofocus=""
                      :value="search_.qs"
                    />
                    <button type="submit" class="btn">
                      <span class="fas fa-search" aria-hidden="true"></span>
                    </button>
                  </form>
                </div>
                <a class="close" href="#close">×</a>
              </div>
              <!-- /search popup -->
            </li>
          </ul>
          <!--/search-right-->
        </div>
        <!-- <ul class="header-search me-lg-4 d-flex">
          <li class="get-btn me-2">
            <a
              href="login.html"
              class="btn btn-style btn-primary"
              title="search"
              ><i class="fas fa-user me-lg-2"></i>
              <span class="btn-texe-inf">Login</span></a
            >
          </li>
          <li class="shopvcart galssescart2 cart cart box_1 get-btn">
            <form action="/#" method="post" class="last">
              <input type="hidden" name="cmd" value="_cart" />
              <input type="hidden" name="display" value="1" />
              <button
                class="top_shopv_cart"
                type="submit"
                name="submit"
                value=""
              >
                <span class="fas fa-shopping-bag me-lg-2"></span>
                <span class="btn-texe-inf">Cart</span>
              </button>
            </form>
          </li>
        </ul> -->
        <!--//search-right-->
        <!-- toggle switch for light and dark theme -->
        <div class="mobile-position">
          <nav class="navigation">
            <!-- <div class="theme-switch-wrapper">
              <label class="theme-switch" for="checkbox">
                <input type="checkbox" id="checkbox" />
                <div class="mode-container">
                  <i class="gg-sun"></i>
                  <i class="gg-moon"></i>
                </div>
              </label>
            </div> -->
          </nav>
        </div>
        <!-- //toggle switch for light and dark theme -->
      </nav>
    </div>
  </header>
  <!--//Header-->
  <section class="w3mid-gap"></section>

  <!-- <header>
    header....
    <br />
    <ul>
      <li><router-link :to="{ name: 'app.page1' }">page1</router-link></li>
      <li><router-link :to="{ name: 'app.page2' }">page2</router-link></li>
    </ul>
  </header> -->
</template>

<style>
.navbar .search-right .popup form input[type="search"]{
  color: #fff !important;
}
</style>


<script>
// import LayoutHeader from "@/views/layout/layout-site-header";
// import LayoutFooter from "@/views/layout/layout-site-footer";

import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";

export default {
  components: {
    // LayoutHeader,
    // LayoutFooter,
  },

  setup() {
    const search_ = reactive({
      qs: ''
    });

    onMounted(function () {
      search_.qs = useRoute().query.search;
      // console.log(useRoute().query.search)
    });

    return {
      search_
    };
  },
};
</script>
